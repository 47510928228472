<template>
  <div id="file-dropzone">
    <div
      @drop="onDrop($event)"
      @dragover="onDragOver($event)"
      @dragleave="onDragLeave($event)"
      @dragend="onDragLeave($event)"
      @dragenter="onDragEnter($event)"
      id="dropzone"
      class="dropzone dz-clickable mx-auto text-center d-flex align-items-center justify-content-center"
    >
      <div
        @click="openFileExplorer"
        class="dz-default dz-message m-auto"
      >
        <v-icon
          :color="this.error ? 'error' : this.iconColor"
          class="mb-2"
          size="50"
          >{{ this.fileIcon }}</v-icon
        >
        <br />
        <span
          class="error-message"
          v-if="this.error"
          >{{ this.error }}</span
        >
        <span v-else-if="!this.file">{{ $t('generic.lang_dropFileOrClickHereToBrowse') }}</span>
        <div v-else>
          <span>{{ this.file.name }}</span>
          <br />

          <!-- remove the file or start import -->
        </div>

        <div
          @click.stop
          v-if="this.file"
        >
          <v-btn
            depressed
            outlined
            color="error"
            class="mt-2"
            v-if="showActions && !hideCancel"
            :disabled="loading"
            @click="removeFile"
          >
            {{ this.$t('generic.lang_delete') }}
          </v-btn>
          <v-btn
            :color="this.iconColor"
            depressed
            class="mt-2"
            v-if="showActions && !hideConfirm"
            :disabled="loading"
            :loading="loading"
            @click="startImport"
          >
            {{ this.$t(this.confirmText) }}
          </v-btn>
        </div>
      </div>
    </div>
    <input
      @change="onFileChange"
      type="file"
      id="file"
      hidden
    />
  </div>
</template>

<script>
export default {
  name: 'FileDropzone',
  props: {
    value: {
      type: File,
      default: null,
    },
    fileIcon: {
      type: String,
      default: 'mdi-file-document',
    },
    iconColor: {
      type: String,
      default: 'primary',
    },
    fileTypes: {
      type: Array,
      default: () => ['*'],
    },
    showActions: {
      type: Boolean,
      default: true,
    },
    hideConfirm: {
      type: Boolean,
      default: false,
    },
    hideCancel: {
      type: Boolean,
      default: false,
    },
    confirmText: {
      type: String,
      default: 'generic.lang_import',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      error: null,
    };
  },
  components: {},
  methods: {
    startImport() {
      this.$emit('startImport');
    },
    openFileExplorer() {
      const fileInput = document.getElementById('file');
      // set the file type
      fileInput.accept = this.fileTypes.join(',');
      fileInput.click();
    },
    removeFile() {
      this.file = null;
    },
    clearErrorStyle() {
      this.error = null;
      document
        .getElementById('dropzone')
        .classList.remove('dz-drag-hover-error');
    },
    onFileChange() {
      const tempFile = document.getElementById('file').files[0];

      // check the file type
      if (!this.fileTypes.includes(tempFile?.type)) {
        this.error = this.$t('generic.lang_invalidFileType');
        document
          .getElementById('dropzone')
          .classList.add('dz-drag-hover-error');
        this.file = null;
        return;
      } else if (tempFile && this.fileTypes.includes(tempFile?.type)) {
        this.file = document.getElementById('file').files[0];
        this.clearErrorStyle();
      }
    },
    isValidFileType(_file) {
      const extension = _file?.name.split('.').pop();
      return (
        _file &&
        (this.fileTypes.includes(_file?.type) ||
          this.fileTypes.includes(`.${extension}`))
      );
    },
    onDrop(e) {
      e.preventDefault();
      e.stopPropagation();

      const tempFile = e.dataTransfer.files[0];

      // check the file type

      if (!this.isValidFileType(tempFile)) {
        this.error = this.$t('generic.lang_invalidFileType');
        document
          .getElementById('dropzone')
          .classList.add('dz-drag-hover-error');

        this.file = null;
        return;
      } else if (tempFile && this.isValidFileType(tempFile)) {
        this.file = e.dataTransfer.files[0];
        document.getElementById('dropzone').classList.remove('dz-drag-hover');
        this.clearErrorStyle();
      }
    },

    onDragOver(e) {
      e.preventDefault();
      e.stopPropagation();

      e.dataTransfer.dropEffect = 'upload';

      const tempFile = e.dataTransfer.files[0];

      // check the file type
      if (tempFile && !this.isValidFileType(tempFile)) {
        this.error = this.$t('generic.lang_invalidFileType');
        this.file = null;
        // add dropzone hover error style
        document
          .getElementById('dropzone')
          .classList.add('dz-drag-hover-error');

        return;
      }

      // add the drop over style to #file-dropzone #dropzone
      document.getElementById('dropzone').classList.add('dz-drag-hover');
    },

    onDragEnter(e) {
      e.preventDefault();

      // add the drop over style to #file-dropzone #dropzone
      document.getElementById('dropzone').classList.add('dz-drag-hover');
    },

    onDragLeave(e) {
      e.preventDefault();
      e.stopPropagation();
      this.clearErrorStyle();
      // remove the drop over style to #file-dropzone #dropzone
      document.getElementById('dropzone').classList.remove('dz-drag-hover');
    },

    onDragEnd(e) {
      e.preventDefault();
      e.stopPropagation();
      this.clearErrorStyle();
      // remove the drop over style to #file-dropzone #dropzone
      document.getElementById('dropzone').classList.remove('dz-drag-hover');
    },
  },
  mounted() {},
  computed: {
    file: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  watch: {},
};
</script>

<style>
#file-dropzone #dropzone {
  border: 2px dashed #eeeeee;
  border-radius: 5px;
  background-color: #fafafa;
  min-height: 150px;
  padding: 20px;
  text-align: center;
  transition: 'border .24s ease-in-out';
}

#file-dropzone #dropzone .dz-message span {
  display: block;
  font-size: 1.5em;
  font-weight: 300;
  user-select: none;
}

#file-dropzone #dropzone .dz-message .note {
  font-size: 0.8em;
  font-weight: 400;
}

/** add the drop over style*/
#file-dropzone #dropzone.dz-drag-hover {
  border-color: #2196f3;
  background: #eaf7ff;
}

#file-dropzone #dropzone .dz-preview {
  display: none;
}

/* add error message style */
#file-dropzone #dropzone .error-message {
  color: #f44336;
  font-size: 0.8em;
  font-weight: 400;
}

#file-dropzone #dropzone.dz-drag-hover-error {
  border-color: #f44336;
  background: #ffebee;
}
</style>
